import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {


  noticias = [
    {link:'https://www.instagram.com/laboratorioromanozte/p/DH8fCpZBWo0/', img:'../../../assets/noticias/noticia92.webp', titulo: 'Día del Veterano y de los Caídos en la Guerra de Malvinas.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DH8YLIWoE83/', img:'../../../assets/noticias/noticia91.webp', titulo: 'Día Mundial de Concientización sobre el Autismo.'},



    {link:'https://www.instagram.com/laboratorioromanozte/p/DHthKwEpfoX/', img:'../../../assets/noticias/noticia90.webp', titulo: '¡Mujeres, su salud es lo primero!'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DHlNBt6tCd1/', img:'../../../assets/noticias/noticia89.webp', titulo: 'Día Nacional de la Memoria por la Verdad y la Justicia.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DHeA90Rhg9U/', img:'../../../assets/noticias/noticia88.webp', titulo: 'Informamos que en este fin de semana largo, el día Lunes 24 permaneceremos cerrado.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DHYYfyXsi6h/', img:'../../../assets/noticias/noticia87.webp', titulo: '¡Feliz cumple, querida ciudad de Zárate!'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DHMIqVRPHUM/', img:'../../../assets/noticias/noticia86.webp', titulo: '¡Agendar tu turno en Laboratorio Romano nunca fue tan fácil!'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DHGk3WJtoUV/', img:'../../../assets/noticias/noticia85.webp', titulo: 'Día Mundial del Glaucoma.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DG8AXfIhTPg/', img:'../../../assets/noticias/noticia84.webp', titulo: 'Hoy celebramos a todas las mujeres que con su fortaleza, dedicación y amor hacen del mundo un lugar mejor. '},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DG6LkraPu89/', img:'../../../assets/noticias/noticia83.webp', titulo: '¡Detecta y maneja el estrés de manera más efectiva!'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGvIUlaIkCI/', img:'../../../assets/noticias/noticia82.webp', titulo: '¡Viví el Carnaval con Salud y Alegría!'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGnuiTrPw1Y/', img:'../../../assets/noticias/noticia81.webp', titulo: 'Informamos que en este fin de semana XXL, el día Lunes 3 y Martes 4 permaneceremos cerrado.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGk1JjdBIcg/', img:'../../../assets/noticias/noticia80.webp', titulo: 'Día Mundial del Trasplante de Órganos y Tejidos.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGVr30ezApf/', img:'../../../assets/noticias/noticia79.webp', titulo: 'Atención de urgencias sin necesidad de turno previo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGDdukVii3F/', img:'../../../assets/noticias/noticia78.webp', titulo: 'Día Mundial de las Cardiopatías Congénitas.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DGBGn5eMLkI/', img:'../../../assets/noticias/noticia77.webp', titulo: 'En nuestro laboratorio realizamos una amplia variedad de estudios químicos para brindarte diagnósticos precisos y confiables.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DF7wQj8hgi6/', img:'../../../assets/noticias/noticia76.webp', titulo: 'Día Mundial de la Mujer Médica.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DF0jSnov2Cj/', img:'../../../assets/noticias/noticia75.webp', titulo: '40 años brindando atención de calidad.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DFgODDwxrRl/', img:'../../../assets/noticias/noticia74.webp', titulo: ' Vitamina D y sistema inmune: una alianza clave.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DFSfJAmtgA3/', img:'../../../assets/noticias/noticia73.webp', titulo: 'Día Mundial contra la Lepra.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DFLFGOtMuki/', img:'../../../assets/noticias/noticia72.webp', titulo: '¿Hacer ejercicio antes de un análisis?'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DE5b2BisWnc/', img:'../../../assets/noticias/noticia71.webp', titulo: 'En Laboratorio Romano trabajamos con todas las obras sociales y prepagas.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DExAz_7voGs/', img:'../../../assets/noticias/noticia70.webp', titulo: 'Día Mundial de la Lucha contra la Depresión.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DEm0gTtI-aI/', img:'../../../assets/noticias/noticia69.webp', titulo: 'Deshidratación: el gran peligro del verano.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DERGGOePbAs/', img:'../../../assets/noticias/noticia68.webp', titulo: '¡Feliz Año Nuevo!'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DEFnc89tvRS/', img:'../../../assets/noticias/noticia67.webp', titulo: 'Tomar alcohol altera los resultados de tus análisis clínicos.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DD_Ejp6N7JQ/', img:'../../../assets/noticias/noticia66.webp', titulo: 'Que la magia de la Navidad llene tu hogar de salud y alegría.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DD15f8xNSYy/', img:'../../../assets/noticias/noticia65.webp', titulo: 'Te informamos que el Laboratorio permanecerá cerrado desde el 24 de diciembre hasta el 1 de enero.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DDxO1YSNa4I/', img:'../../../assets/noticias/noticia64.webp', titulo: 'Extracción personalizada en nuestro estacionamiento.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DDe79zDMMwC/', img:'../../../assets/noticias/noticia63.webp', titulo: 'En nuestro laboratorio podés recibir tus resultados de tres maneras.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DDZ5VzKI_t4/', img:'../../../assets/noticias/noticia62.webp', titulo: 'Volvemos a nombrar cada una de las obras sociales y prepagas con las que trabajamos.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DDIIr5iq8vp/', img:'../../../assets/noticias/noticia61.webp', titulo: 'Te contamos cuales son las indicaciones para la muestra de Hormona del Estrés: Cortisol.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DDC4d_hy_bk/', img:'../../../assets/noticias/noticia60.webp', titulo: 'Día Mundial del SIDA.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DC6yDdsKThn/', img:'../../../assets/noticias/noticia59.webp', titulo: '¿Puedo tomar agua antes de una extracción de sangre?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DC15qS9sngy/', img:'../../../assets/noticias/noticia58.webp', titulo: 'Si sos afiliado de GALENO podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCo1GdUpJRj/', img:'../../../assets/noticias/noticia57.webp', titulo: '¿Sabías que el SIBO puede estar detrás de tus problemas digestivos?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCmB79IJLe1/', img:'../../../assets/noticias/noticia56.webp', titulo: 'Día de la Soberanía Nacional.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCWm2GBv5Jh/', img:'../../../assets/noticias/noticia55.webp', titulo: ' Día Mundial de la Diabetes.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCUr5OvBs8t/', img:'../../../assets/noticias/noticia54.webp', titulo: 'CALILAB 2024: El 6, 7 y 8 de Noviembre se realizó el congreso bioquimico más importante del país y latinoamerica.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCRkswsCnpk/', img:'../../../assets/noticias/noticia53.webp', titulo: '¿Sabías que el ayuno es clave para obtener resultados precisos en tus análisis de laboratorio? '},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCJ4Z8GtjuV/', img:'../../../assets/noticias/noticia52.webp', titulo: 'Día de la primera transfusión de sangre.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DCHxCrVCeqj/', img:'../../../assets/noticias/noticia51.webp', titulo: 'Dengue: Síntomas.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DB_hSg2tFBj/', img:'../../../assets/noticias/noticia50.webp', titulo: 'En Laboratorio Romano atendemos por Prepagas, Obras Sociales y Particulares.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBuj1Vkppm8/', img:'../../../assets/noticias/noticia49.webp', titulo: 'Consejos para después de una extracción de sangre.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/DBjIM2lMxQB/', img:'../../../assets/noticias/noticia48.webp', titulo: 'Inauguramos nueva marquesina con nuestro logo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBbbAftoF0w/', img:'../../../assets/noticias/noticia47.webp', titulo: 'Si sos afiliado de SANCORSALUD podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DBWk0csCeWS/', img:'../../../assets/noticias/noticia46.webp', titulo: 'Día Mundial de la Prevención contra la Osteoporosis.'},


  ];



  constructor() { }

  ngOnInit() {
  }

}
